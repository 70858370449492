<template>
    <v-menu offset-y v-if="templateItems.length > 0">
        <template v-slot:activator="{ on, attrs }">

                <v-icon  v-bind="attrs" v-on="on" :title="$t('list_of_templates')"
                         size="18"
                         class="add_icon pr-2"
                         color="secondary">mdi-download-outline</v-icon>

        </template>
        <v-list>
            <v-subheader class="text-info__label text-uppercase" style="height: 30px;">{{$t('list_of_templates')}}</v-subheader>
            <v-list-item style="min-height: 30px" v-for="(item, index) in templateItems" :key="'template_' + index" @click="downloadTemplate(item)">
                <v-list-item-subtitle>{{ (index + 1)}}) {{ item.name }}</v-list-item-subtitle>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "GetTemplates",
    props: {
        entity_type: {
            type: String,
            required: true
        },
        entity_id: {
            type: [String, Number],
            required: true
        },
        record_id: {
            type: [String, Number],
            required: true
        }
    },
    data() {
        return {
            templateItems: []
        };
    },
    computed: {
        ...mapGetters(['lang']),
    },
    mounted() {
        this.getTemplates();
    },
    methods: {
        async getTemplates() {
            this.loading = true
            let params = {};
            this.template_table_type = null;
            if (this.lang) {
                params.language = this.lang;
            }
            if (this.entity_type) {
                params.entity_type = this.entity_type;
            }
            if (this.entity_id) {
                params.entity_id = this.entity_id;
            }
            await this.$http
                .get(`admin/get_template`, {params: params})
                .then(res => {
                    this.templateItems = res.body.data


                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_template'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async downloadTemplate(item){
            var _this = this;
            this.progressDeal = 0
            this.loadingDeal = true
            let params = {}
            if (this.lang) {
                params.language = this.lang;
            }
            if (this.entity_type) {
                params.type = this.entity_type;
            }
            if (this.record_id) {
                params.record_id = this.record_id;
            }
            params.template_id = item.id;
            await this.$http
                .get(`admin/generate_document`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progressDeal = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    const url = res.body.data.url;
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    a.download = res.body.data.name;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_template'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progressDeal = 0
                    this.loadingDeal = false
                })
        },
    }
};
</script>

<style scoped>

</style>
